import React from 'react'

const Footer = () => (
    <footer className="footer">
        <div className="container">
            <div className="row">
                <div className="column"></div>
                <div className="column text-center">
                    <p><small>Copyright Waxins &copy; 2019</small></p>
                </div>
                <div className="column"></div>
            </div>
        </div>
    </footer>
)

export default Footer;

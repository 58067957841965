import { graphql, StaticQuery } from 'gatsby'
import React from 'react'
import Logoblack from "../images/logo.png"
import MapImg from "../images/map.jpg"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

const Contact = () => (
  <StaticQuery query={graphql`
    query{
      contentfulHomePage{
        contactTitle
        contactinfo{
          json
        }
      },
    }
  `}

  render={data => {
    // Set ImageData.

    return (
      <section id="contact">
        <div className="text-center"
        data-sal="fade"
        data-sal-duration="800"
        data-sal-easing="cubic-bezier(0.215, 0.61, 0.355, 1)">
          <img className="contact__logo" src={Logoblack} alt="logo" />
        </div>
        <div className="container mt">
          <div className="row">
            <div className="column column-text"
            data-sal="slide-up"
            data-sal-duration="800"
            data-sal-easing="cubic-bezier(0.215, 0.61, 0.355, 1)">
              <h2>{data.contentfulHomePage.contactTitel}</h2>
              <div className="contact__info">{documentToReactComponents(data.contentfulHomePage.contactinfo.json)}</div>
            </div>
            <div className="column column-large"
            data-sal="fade"
            data-sal-duration="800"
            data-sal-delay="300"
            data-sal-easing="cubic-bezier(0.215, 0.61, 0.355, 1)">
              <img className="w-100" src={MapImg} alt="Waxins" />
            </div>
          </div>
        </div>
      </section>

    )
  }
  }
 />

)

export default Contact;

import { graphql, StaticQuery } from 'gatsby'
import React from 'react'
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

const Join = () => (
  <StaticQuery query={graphql`
    query{
     contentfulHomePage{
        joinUsTitle
      }
      allContentfulJobItem{
        edges{
          node{
            jobItemTitle
            jobDecription{
              json
            }
            jobContactPerson
          }
        }
      }
    }
  `}

  render={data => {
    // Set ImageData.

    return (
      <section id="join-section">
        <div className="container">
          <div className="row text-center">
            <div className="column"
            data-sal="fade"
            data-sal-duration="800"
            data-sal-delay="300"
            data-sal-easing="cubic-bezier(0.215, 0.61, 0.355, 1)">
              <h1>{data.contentfulHomePage.joinUsTitle}</h1>
            </div>
          </div>
          <div className="row">
            <div className="column column-text"
            data-sal="slide-up"
            data-sal-duration="800"
            data-sal-easing="cubic-bezier(0.215, 0.61, 0.355, 1)">
            {data.allContentfulJobItem.edges.map((job) => {
                return(
                  <div className="job">
                    <h4>{job.node.jobItemTitle}</h4>
                    {documentToReactComponents(job.node.jobDecription.json)}
                    <div><a href={job.node.jobContactPerson} target='_blank' className="button button-outline">Apply Here</a></div>
                  </div>
                )
            })}
            </div>
          </div>
        </div>
      </section>

    )
  }
  }
 />

)

export default Join;

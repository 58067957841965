import { graphql, StaticQuery } from 'gatsby'
import React from 'react'
import Img from 'gatsby-image'

import { documentToReactComponents } from "@contentful/rich-text-react-renderer"



const Kitchen = () => (
    <StaticQuery query={graphql`
      query KitchenQuery {
          contentfulVision{
            kitchenTitle
            kitchenText{
              json
            }
            kitchenImage{
              fluid(quality:100, maxWidth:2000){
                ...GatsbyContentfulFluid
              }
            }
          }
        }
    `}

  render={data => {
    // Set ImageData.

    return (
      <section>
        <div className="container">
          <div className="row">
            <div className="column"
            data-sal="fade"
            data-sal-duration="800"
            data-sal-delay="300"
            data-sal-easing="cubic-bezier(0.215, 0.61, 0.355, 1)">
              <Img className="h-100" fluid={data.contentfulVision.kitchenImage.fluid} />
            </div>
            <div className="column column-text"
            data-sal="slide-up"
            data-sal-duration="800"
            data-sal-easing="cubic-bezier(0.215, 0.61, 0.355, 1)">
              <h1>{data.contentfulVision.kitchenTitle}</h1>
              <div>{documentToReactComponents(data.contentfulVision.kitchenText.json)}</div>
            </div>
          </div>
        </div>
      </section>
    )
  }
  }
 />

)

export default Kitchen;

import { graphql, StaticQuery } from 'gatsby'
import React from 'react'
import Img from 'gatsby-image'
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"


const About = () => (
    <StaticQuery query={graphql`
      query AboutQuery {
        contentfulHomePage{
          aboutTitle
          aboutText{
            json
          }
          aboutImage{
            fluid(quality:100){
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    `}

  render={data => {
    // Set ImageData.

    return (
      <section>
        <div className="container" id="about-section">
          <div className="row">
            <div className="column column-text"
            data-sal="slide-up"
            data-sal-duration="800"
            data-sal-easing="cubic-bezier(0.215, 0.61, 0.355, 1)">
              <h1>{data.contentfulHomePage.aboutTitle}</h1>
              <div>{documentToReactComponents(data.contentfulHomePage.aboutText.json)}</div>
            </div>
            <div className="column"
              data-sal="fade"
              data-sal-duration="800"
              data-sal-delay="300"
              data-sal-easing="cubic-bezier(0.215, 0.61, 0.355, 1)">
              <Img className="h-100" fluid={data.contentfulHomePage.aboutImage.fluid}/>
            </div>
          </div>
        </div>
      </section>
    )
  }
  }
 />

)

export default About;

import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';

const Imagegrid = () => (
  <StaticQuery
    query={graphql`
    query{
        allContentfulImageGridItem(limit: 8){
          edges{
            node{
              imageGrid{
                fluid{
                  ...GatsbyContentfulFluid
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      console.log(data.allContentfulImageGridItem);
      return (
      <section id="imagegrid-section">
        <div className="container container-fluid">
        <div className="row">
            {data.allContentfulImageGridItem.edges.map((item) => {
                return(
                  <div className="column column-insta no-gutters">
                    <Img fluid={item.node.imageGrid.fluid} />
                  </div>
                )
            })}
          </div>
        </div>
      </section>
      )
    }
  }
  />
);

export default Imagegrid;

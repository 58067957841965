import { graphql, StaticQuery } from 'gatsby'
import React from 'react'
import Img from 'gatsby-image'
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"



const Bar = () => (
    <StaticQuery query={graphql`
      query BarQuery {
          contentfulHomePage{
            barTitle
            barText{
              json
            }
            barImage{
              fluid(quality:100, maxWidth:2000){
                ...GatsbyContentfulFluid
              }
            }
            wineMenu{
              file{
                url
              }
            }
            drinkMenu{
              file{
                url
              }
            }
          }
        }
    `}

  render={data => {
    // Set ImageData.

    return (
      <section id="bar-section">
        <div className="container">
          <div className="row">
            <div className="column"
            data-sal="fade"
            data-sal-duration="800"
            data-sal-delay="300"
            data-sal-easing="cubic-bezier(0.215, 0.61, 0.355, 1)">
              <Img className="h-100" fluid={data.contentfulHomePage.barImage.fluid} />
            </div>
            <div className="column column-text"
            data-sal="slide-up"
            data-sal-duration="800"
            data-sal-easing="cubic-bezier(0.215, 0.61, 0.355, 1)">
              <h1>{data.contentfulHomePage.barTitle}</h1>
              <div>{documentToReactComponents(data.contentfulHomePage.barText.json)}</div>
              <a href={data.contentfulHomePage.drinkMenu.file.url} target='_blank' className="button button-outline">Drink list</a>
            </div>
          </div>
        </div>
      </section>
    )
  }
  }
 />

)

export default Bar;

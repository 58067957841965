import React from "react"
import Logowhite from "../images/logo_white.png"


const VideoHeader = () => {

return(

  <div id="video-section">
    <div className="video-background">
      <video autoPlay muted playsInline loop id="video" src="https://player.vimeo.com/external/379737405.hd.mp4?s=ad8ba11d6115eac7afcb8b4013f6ca054a2d9780&profile_id=174"></video>
    </div>
    <div className="video-overlay"></div>
    <img className="logo" src={Logowhite} alt="" />

  </div>

  )
}

export default VideoHeader

/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import Header from './header'
import VideoHeader from './videohero'
import About from './about'
import Restaurant from './restaurant'
import Bar from './bar'
import Catering from './catering'
import Imagegrid from './imagegrid'
import Kitchen from './kitchen'
import History from './history'
import Team from './team'
import Join from './join'
import Contact from './contact'
import Footer from './footer'
import DocHead from '../components/helmet'
import '../styles/app.scss'

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]', {
    speed: 300,
    offset: 50
  })
}

const Layout = () => {
  return (
      <main>
        <DocHead title="Home"/>
        <Header/>
        <VideoHeader/>
        <About/>
        <Restaurant/>
        <Bar/>
        <Catering/>
        <Imagegrid/>
        <Kitchen/>
        <History/>
        <Team/>
        <Join/>
        <Contact/>
        <Footer/>
      </main>
  )

}

export default Layout

import { graphql, StaticQuery } from 'gatsby'
import React from 'react'
import Img from 'gatsby-image'

const Team = () => (
  <StaticQuery query={graphql`
    query{
      contentfulHomePage{
        teamTitle
      }
      allContentfulTeamMember(sort:{fields:sortOrder, order:ASC}){
          edges{
            node{
              teamMemberTitle
              teamMemberName
              teamMemberEmail
              teamMemberImage{
                fluid(quality:100){
                  ...GatsbyContentfulFluid
                }
              }
            }
          }
      }
    }
  `}

  render={data => {
    // Set ImageData.

    return (
      <section id="team-section">
        <div className="container">
          <div className="row text-center">
            <div className="column"
            data-sal="fade"
            data-sal-duration="800"
            data-sal-delay="300"
            data-sal-easing="cubic-bezier(0.215, 0.61, 0.355, 1)">
              <h1>{data.contentfulHomePage.teamTitle}</h1>
            </div>
          </div>
          <div className="row wrap text-center">
            {data.allContentfulTeamMember.edges.map((item) => {
                return(
                  <div className="column column-text column-team">
                    <Img className="team__image mb-2" fluid={item.node.teamMemberImage.fluid} />
                    <p className="text-bold mb">{item.node.teamMemberTitle}</p>
                    <p>{item.node.teamMemberName}<br/>
                    {item.node.teamMemberEmail}</p>
                  </div>
                )
            })}
          </div>
        </div>
      </section>

    )
  }
  }
 />

)

export default Team;

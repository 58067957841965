import { graphql, StaticQuery } from 'gatsby'
import React from 'react'
import Img from 'gatsby-image'
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"




const History = () => (
    <StaticQuery query={graphql`
      query HistoryQuery {
          contentfulVision{
            historyImage{
              fluid(quality:100){
                ...GatsbyContentfulFluid
              }
            }
            historyTitle
            historyText{
              json
            }
          }
        }
    `}

  render={data => {
    // Set ImageData.

    return (
      <section id="catering-section">
        <div className="container">
          <div className="row">
            <div className="column medium-order-2"
            data-sal="fade"
            data-sal-duration="800"
            data-sal-delay="300"
            data-sal-easing="cubic-bezier(0.215, 0.61, 0.355, 1)">
              <Img className="h-100" fluid={data.contentfulVision.historyImage.fluid} />
            </div>
            <div className="column column-text medium-order-1"
            data-sal="slide-up"
            data-sal-duration="800"
            data-sal-easing="cubic-bezier(0.215, 0.61, 0.355, 1)">
              <h1>{data.contentfulVision.historyTitle}</h1>
              <div>{documentToReactComponents(data.contentfulVision.historyText.json)}</div>
            </div>
          </div>
        </div>
      </section>

    )
  }
  }
 />

)

export default History;

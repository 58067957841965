import { graphql, StaticQuery } from 'gatsby'
import React from 'react'
import Img from 'gatsby-image'
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"



const Restaurant = () => (
    <StaticQuery query={graphql`
      query RestaurantQuery {
          contentfulHomePage{
            restaurantImage{
              fluid(quality:100, maxWidth:1920){
                ...GatsbyContentfulFluid
              }
            }
            restaurantTitle
            restaurantText{
              json
            }
            wineMenu{
              file{
                url
              }
            }
            restaurantMenu{
              file{
                url
              }
            }
            dessertMenu{
              file{
                url
              }
            }
          }
        }
    `}

  render={data => {
    // Set ImageData.
    return (
      <section id="restaurant-section">
        <div className="container">
          <div className="row">
            <div className="column"
            data-sal="fade"
            data-sal-duration="800"
            data-sal-delay="300"
            data-sal-easing="cubic-bezier(0.215, 0.61, 0.355, 1)">
              <Img fluid={data.contentfulHomePage.restaurantImage.fluid} />
            </div>
          </div>
          <div className="row">
            <div className="column column-text"
            data-sal="slide-up"
            data-sal-duration="800"
            data-sal-easing="cubic-bezier(0.215, 0.61, 0.355, 1)">
              <h1 className="mt">{data.contentfulHomePage.restaurantTitle}</h1>
            </div>
          </div>
          <div className="row"
          data-sal="slide-up"
          data-sal-duration="800"
          data-sal-easing="cubic-bezier(0.215, 0.61, 0.355, 1)">
            <div className="column column-text">
              <div>{documentToReactComponents(data.contentfulHomePage.restaurantText.json)}</div>
            </div>
            <div className="column column-text">
              <a href={data.contentfulHomePage.restaurantMenu.file.url} target='_blank' className="button button-outline">Menu</a>
              <a href={data.contentfulHomePage.dessertMenu.file.url} target='_blank' className="button button-outline">Dessert</a>
              <a href={data.contentfulHomePage.wineMenu.file.url} target='_blank' className="button button-outline">Wine list</a>

            </div>
          </div>
        </div>
      </section>

    )
  }
  }
 />

)

export default Restaurant;
